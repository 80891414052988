<template>
    <b-container class="pt-5 pb-5">
        <h1 class="mb-4">Herroepingsrecht</h1>
        <h2>Herroepingsrecht (Wet koop op afstand)</h2>
        <p>Als consument heb je het recht om je diensten te annuleren binnen een bedenktijd van 14 kalenderdagen nadat een bestelling is geplaatst. Dit wordt ook wel het herroepingsrecht genoemd.</p><p>Annuleren tijdens de bedenktijd kost je niets en doe je makkelijk via het bestelsysteem. Voor zakelijke klanten, ook kleine ondernemers, geldt het herroepingsrecht niet.</p>
        <h2>Niet van kracht bij (online) tickets</h2>
        <p>Bij het afronden van jouw bestelling van een ticket, geef jij ons de opdracht om je ticket direct voor jou te registreren bij de daarvoor verantwoordelijke organisatie. Dit doen we binnen de bedenk-tijd van 14 dagen waarin het herroepingsrecht geldt bij de Wet koop op afstand.</p>
        <p>Aangezien we een succesvolle bestelling niet meer terug kunnen draaien, vragen we je om bij elke bestelling <u>afstand te doen van je herroepingsrecht</u>. Voor andere diensten blijft dit recht wel gelden.</p>
    </b-container>
</template>

<script>
    export default {
        metaInfo: {
            title: 'Herroepingsrecht',
        }
    }
</script>